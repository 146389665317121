:root {
	--timing-func-windup-in-out: cubic-bezier(0.95, -0.71, 0.29, 1.3);
	--timing-func-windup-in-out-fast: cubic-bezier(1, -0.35, 0, 1.31);

	--timing-func-spring-out: cubic-bezier(0.32, 1.52, 0.45, 0.9);
	--timing-func-spring-out-soft: cubic-bezier(0.28, 0.77, 0.13, 1.18);

	--timing-func-ease-out-soft: cubic-bezier(0.39, 0.8, 0.25, 1.06);
	--timing-func-gradual: cubic-bezier(0.3, 0.52, 0.41, 0.81);
	--timing-func-gentle-out: cubic-bezier(0.19, 0.96, 0.37, 1);
	--timing-func-aggressive-overshoot: cubic-bezier(0, 1, 0.5, 2.5);
}

.shimmer {
	position: relative;
	overflow: hidden !important;

	--shimmer-delay: 0;
	--shimmer-duration: 5;
	--shimmer-direction: forwards;
	--shimmer-color-rgb: 255, 255, 255;
}

.shimmer:before {
	/* double 
    background: linear-gradient(
		transparent 0%,
		rgba(255, 255, 255, 0.05) 23%,
		rgba(255, 255, 255, 0.25) 25%,
		rgba(255, 255, 255, 0.05) 29%,
		transparent 35%,
		transparent 64%,
		rgba(255, 255, 255, 0.1) 75%,
		rgba(255, 255, 255, 0.5) 77%,
		rgba(255, 255, 255, 0.1) 85%,
		transparent 100%
	); */

	content: '';
	z-index: 10;
	position: absolute;
	height: 100%;
	width: 300%;
	top: -200%;
	left: -200%;
	background: linear-gradient(transparent 0%, rgba(var(--shimmer-color-rgb), 0.1) 45%, rgba(var(--shimmer-color-rgb), 0.5) 47%, rgba(var(--shimmer-color-rgb), 0.1) 55%, transparent 100%);
	transform: rotate(-45deg) scale(5, 2);

	animation-name: shimmer;
	animation-delay: calc(var(--shimmer-delay) * 1s);
	animation-duration: calc(var(--shimmer-duration) * 1s);
	animation-timing-function: cubic-bezier(1, 0.29, 0.4, 0.97);
	animation-iteration-count: infinite;
	animation-direction: var(--shimmer-direction);
}

@keyframes shimmer {
	0% {
		top: -200%;
		left: -200%;
	}
	75% {
		top: 75%;
		left: 75%;
	}
	100% {
		top: 75%;
		left: 75%;
	}
}

.placeholder-wave {
	mask-image: linear-gradient(135deg, rgba(0, 0, 0, 0.9) 55%, rgba(0, 0, 0, 0.75) 80%, rgba(0, 0, 0, 0.9) 90%);
	-webkit-mask-image: linear-gradient(135deg, rgba(0, 0, 0, 0.9) 55%, rgba(0, 0, 0, 0.75) 80%, rgba(0, 0, 0, 0.9) 90%);
	mask-size: 400% 400%;
	-webkit-mask-size: 400% 400%;
	mask-position: -100% -100%;
	-webkit-mask-position: -100% -100%;
	animation: placeholder-wave 2s linear infinite;
}

.placeholder-pulse {
	animation: placeholder-pulse 2s ease-in-out infinite alternate;
}

.placeholder-wave.placeholder-pulse {
	animation: placeholder-wave 2s linear infinite, placeholder-pulse 2s ease-in-out infinite alternate;
}

@keyframes placeholder-wave {
	100% {
		mask-position: -100% -100%;
		-webkit-mask-position: -100% -100%;
	}
	0% {
		mask-position: 100% 100%;
		-webkit-mask-position: 100% 100%;
	}
}

@keyframes placeholder-pulse {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.15);
	}
}

.fade-in {
	--fade-in-duration: 0.75s;
	--fade-in-delay: 0s;
	animation: fade-in var(--fade-in-duration) ease-in-out var(--fade-in-delay) both;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.slide-in {
	--slide-in-delay: 0s;
	--slide-in-x: -10%;
	--slide-in-y: 0px;
	animation: slide-in 0.75s ease-in-out var(--slide-in-delay) both;

	position: relative;
}

@keyframes slide-in {
	0% {
		left: var(--slide-in-x);
		top: var(--slide-in-y);
	}
	100% {
		left: 0;
		top: 0;
	}
}

.levitate {
	--levitate-delay: 0s;
	animation: levitate 0.5s ease-in-out var(--levitate-delay) both;
}

@keyframes levitate {
	0% {
		transform: translateY(-3px);
		box-shadow: 0px 0px 0px var(--nmd-tk-gray-600), 0px 0px 0px var(--nmd-tk-mid);
	}
	100% {
		transform: translateY(0px);
		box-shadow: 0px 1px 0px var(--nmd-tk-gray-600), 0px 10px 10px var(--nmd-tk-mid);
	}
}

.hovering {
	--hovering-delay: 0s;
	--hovering-duration: 1s;
	--hovering-distance: 0.25rem;
	animation: hovering var(--hovering-duration) ease-in-out infinite alternate var(--hovering-delay) both;
}

@keyframes hovering {
	0% {
		transform: translateY(calc(var(--hovering-distance) * -1));
	}

	100% {
		transform: translateY(var(--hovering-distance));
	}
}

.transform {
	--scaleX: 1;
	--scaleY: 1;
	--scaleZ: 1;

	--translateX: 0;
	--translateY: 0;
	--translateZ: 0;

	--rotateX: 0deg;
	--rotateY: 0deg;
	--rotateZ: 0deg;

	--top: initial;
	--bottom: initial;
	--left: initial;
	--right: initial;

	--perspective: 0;
	--zIndex: initial;

	position: absolute;
	z-index: var(--zIndex);
	top: var(--top);
	bottom: var(--bottom);
	left: var(--left);
	right: var(--right);
	transform: translateX(var(--translateX)) translateY(var(--translateY)) translateZ(var(--translateZ)) scaleX(var(--scaleX)) scaleY(var(--scaleY)) scaleZ(var(--scaleZ)) rotateX(var(--rotateX))
		rotateY(var(--rotateY)) rotateZ(var(--rotateZ)) perspective(var(--perspective));
}

.transform-3d {
	--perspective: 1000px;
	transform-style: preserve-3d;
	perspective: var(--perspective);
}

.center-vertical {
	--top: 50%;
	--translateY: -50%;
}

.center-horizontal {
	--left: 50%;
	--translateX: -50%;
}

.center-both {
	--top: 50%;
	--translateY: -50%;

	--left: 50%;
	--translateX: -50%;
}

.flip-vertical {
	--scaleY: -1;
}

.flip-horizontal {
	--scaleX: -1;
}

.scroll-snap-container {
	--scroll-snap-direction: y; /* x y both */
	--scroll-snap-enforcement: mandatory; /* mandatory proximity */

	scroll-snap-type: var(--scroll-snap-direction) var(--scroll-snap-enforcement);
}

.scroll-snap-element {
	--scroll-snap-align: start; /* start center end */

	scroll-snap-align: var(--scroll-snap-align);
}

.transition {
	--transition-property: all;
	--transition-duration: 0.25s;
	--transition-timing-function: ease-in-out;
	--transition-delay: 0s;
	--transition-behavior: allow-discrete;

	transition-property: var(--transition-property);
	transition-duration: var(--transition-duration);
	transition-timing-function: var(--transition-timing-function);
	transition-delay: var(--transition-delay);
	transition-behavior: var(--transition-behavior);
}

.bg-glass {
	--background-color-rgb: var(--nmd-tk-white-rgb);
	--background-opacity: 0.25;
	--blur: 5px;

	backdrop-filter: blur(var(--blur));
}

.bg-glass-colored {
	background-color: rgba(var(--background-color-rgb), var(--background-opacity)) !important;
}

.bg-glass-light {
	--background-color-rgb: var(--nmd-tk-light-rgb);
	--background-opacity: 0.75;

	background-color: rgba(var(--background-color-rgb), var(--background-opacity)) !important;
}

.bg-glass-dark {
	--background-color-rgb: var(--nmd-tk-dark-rgb);
	--background-opacity: 0.75;

	background-color: rgba(var(--background-color-rgb), var(--background-opacity)) !important;
}

.shadow-pop {
	--shadow-pop-light-rgb: var(--nmd-tk-light-rgb);
	--shadow-pop-shadow-rgb: var(--nmd-tk-dark-rgb);

	--shadow-pop-width: 1px;
	--shadow-pop-opacity: 0.5;

	--shadow-pop-width-current: var(--shadow-pop-width);
	--shadow-pop-opacity-current: var(--shadow-pop-opacity);

	&:not(.shadow-pop-border):not(.shadow-pop-pseudo) {
		box-shadow: calc(var(--shadow-pop-width-current) * -1) calc(var(--shadow-pop-width-current) * -1) calc(var(--shadow-pop-width-current) * 2)
				rgba(var(--shadow-pop-light-rgb), var(--shadow-pop-opacity-current)),
			var(--shadow-pop-width-current) var(--shadow-pop-width-current) calc(var(--shadow-pop-width-current) * 2) rgba(var(--shadow-pop-shadow-rgb), var(--shadow-pop-opacity-current));
	}

	&-border {
		border-width: var(--shadow-pop-width-current);
		border-style: solid;
		border-top-color: rgba(var(--shadow-pop-light-rgb), var(--shadow-pop-opacity-current)) !important;
		border-left-color: rgba(var(--shadow-pop-light-rgb), var(--shadow-pop-opacity-current)) !important;
		border-bottom-color: rgba(var(--shadow-pop-shadow-rgb), var(--shadow-pop-opacity-current)) !important;
		border-right-color: rgba(var(--shadow-pop-shadow-rgb), var(--shadow-pop-opacity-current)) !important;
	}

	&-pseudo {
		position: relative !important;
	}

	&-pseudo:after {
		content: '';
		pointer-events: none;
		position: absolute;

		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		border-radius: inherit;

		box-shadow: calc(var(--shadow-pop-width-current) * -1) calc(var(--shadow-pop-width-current) * -1) calc(var(--shadow-pop-width-current) * 2)
				rgba(var(--shadow-pop-light-rgb), var(--shadow-pop-opacity-current)),
			var(--shadow-pop-width-current) var(--shadow-pop-width-current) calc(var(--shadow-pop-width-current) * 2) rgba(var(--shadow-pop-shadow-rgb), var(--shadow-pop-opacity-current));
	}

	&-hover {
		--shadow-pop-width-current: 0px;
		--shadow-pop-opacity-current: 0;

		&:hover {
			--shadow-pop-width-current: var(--shadow-pop-width);
			--shadow-pop-opacity-current: var(--shadow-pop-opacity);
		}

		&-chaser:hover {
			--shadow-pop-width-current: var(--shadow-pop-width);
			--shadow-pop-opacity-current: var(--shadow-pop-opacity);
			animation: shadow-pop-chaser 0.75s linear infinite;
		}
	}

	&-chaser {
		animation: shadow-pop-chaser 0.75s linear infinite;
	}
}

@keyframes shadow-pop-chaser {
	0% {
		box-shadow: calc(var(--shadow-pop-width-current) * 3 * -1) calc(var(--shadow-pop-width-current) * 3 * -1) calc(var(--shadow-pop-width-current) * 2)
				rgba(var(--shadow-pop-light-rgb), var(--shadow-pop-opacity-current)),
			calc(var(--shadow-pop-width-current) * 3) calc(var(--shadow-pop-width-current) * 3) calc(var(--shadow-pop-width-current) * 2)
				rgba(var(--shadow-pop-shadow-rgb), var(--shadow-pop-opacity-current));
	}
	25% {
		box-shadow: calc(var(--shadow-pop-width-current) * 3 * -1) calc(var(--shadow-pop-width-current) * 3) calc(var(--shadow-pop-width-current) * 2)
				rgba(var(--shadow-pop-light-rgb), var(--shadow-pop-opacity-current)),
			calc(var(--shadow-pop-width-current) * 3) calc(var(--shadow-pop-width-current) * 3 * -1) calc(var(--shadow-pop-width-current) * 2)
				rgba(var(--shadow-pop-shadow-rgb), var(--shadow-pop-opacity-current));
	}
	50% {
		box-shadow: calc(var(--shadow-pop-width-current) * 3) calc(var(--shadow-pop-width-current) * 3) calc(var(--shadow-pop-width-current) * 2)
				rgba(var(--shadow-pop-light-rgb), var(--shadow-pop-opacity-current)),
			calc(var(--shadow-pop-width-current) * 3 * -1) calc(var(--shadow-pop-width-current) * 3 * -1) calc(var(--shadow-pop-width-current) * 2)
				rgba(var(--shadow-pop-shadow-rgb), var(--shadow-pop-opacity-current));
	}
	75% {
		box-shadow: calc(var(--shadow-pop-width-current) * 3) calc(var(--shadow-pop-width-current) * 3 * -1) calc(var(--shadow-pop-width-current) * 2)
				rgba(var(--shadow-pop-light-rgb), var(--shadow-pop-opacity-current)),
			calc(var(--shadow-pop-width-current) * 3 * -1) calc(var(--shadow-pop-width-current) * 3) calc(var(--shadow-pop-width-current) * 2)
				rgba(var(--shadow-pop-shadow-rgb), var(--shadow-pop-opacity-current));
	}
	100% {
		box-shadow: calc(var(--shadow-pop-width-current) * 3 * -1) calc(var(--shadow-pop-width-current) * 3 * -1) calc(var(--shadow-pop-width-current) * 2)
				rgba(var(--shadow-pop-light-rgb), var(--shadow-pop-opacity-current)),
			calc(var(--shadow-pop-width-current) * 3) calc(var(--shadow-pop-width-current) * 3) calc(var(--shadow-pop-width-current) * 2)
				rgba(var(--shadow-pop-shadow-rgb), var(--shadow-pop-opacity-current));
	}
}

.text-outline {
	--outline-width: 1px;
	--outline-color: var(--nmd-tk-body-bg);
	--outline-text-color: var(--nmd-tk-body-color);
}

@supports (-webkit-text-stroke: var(--outline-width) var(--outline-color)) {
	.text-outline {
		-webkit-text-stroke-width: var(--outline-width);
		-webkit-text-stroke-color: var(--outline-color);

		-webkit-text-stroke: var(--outline-width) var(--outline-color);

		-webkit-text-fill-color: var(--outline-text-color); /* Will override color (regardless of order) */
	}
}

@supports not (-webkit-text-stroke: var(--outline-width) var(--outline-color)) {
	.text-outline {
		color: var(--outline-text-color);
		text-shadow: var(--outline-width) var(--outline-width) 0 var(--outline-color), calc(var(--outline-width) * -1) var(--outline-width) 0 var(--outline-color),
			calc(var(--outline-width) * -1) calc(var(--outline-width) * -1) 0 var(--outline-color), var(--outline-width) calc(var(--outline-width) * -1) 0 var(--outline-color);
	}
}

@property --lightray-color {
	syntax: '<color>';
	inherits: false;
	initial-value: rgba(var(--nmd-tk-white-rgb), 0.35);
}

@property --lightray-top-x {
	syntax: '<length-percentage>';
	inherits: false;
	initial-value: 50%;
}
@property --lightray-top-y {
	syntax: '<length-percentage>';
	inherits: false;
	initial-value: 50%;
}
@property --lightray-top-opacity {
	syntax: '<number>';
	inherits: false;
	initial-value: 1;
}

@property --lightray-mid-x {
	syntax: '<length-percentage>';
	inherits: false;
	initial-value: 50%;
}
@property --lightray-mid-y {
	syntax: '<length-percentage>';
	inherits: false;
	initial-value: 50%;
}
@property --lightray-mid-opacity {
	syntax: '<number>';
	inherits: false;
	initial-value: 1;
}

@property --lightray-bot-x {
	syntax: '<length-percentage>';
	inherits: false;
	initial-value: 50%;
}
@property --lightray-bot-y {
	syntax: '<length-percentage>';
	inherits: false;
	initial-value: 50%;
}
@property --lightray-bot-opacity {
	syntax: '<number>';
	inherits: false;
	initial-value: 1;
}

@property --lightray-shift {
	syntax: '<length-percentage>';
	inherits: false;
	initial-value: 0rem;
}

@property --mask-inner-start {
	syntax: '<length-percentage>';
	inherits: false;
	initial-value: 0%;
}
@property --mask-inner-end {
	syntax: '<length-percentage>';
	inherits: false;
	initial-value: 0%;
}

@property --mask-outer-start {
	syntax: '<length-percentage>';
	inherits: false;
	initial-value: 0%;
}
@property --mask-outer-end {
	syntax: '<length-percentage>';
	inherits: false;
	initial-value: 0%;
}

.lightrays:before {
	content: '';
	pointer-events: none;
	position: absolute;
	z-index: -1;

	top: 0%;
	left: 50%;
	width: 250%;
	height: 475%;

	border-bottom-left-radius: 50%;
	border-bottom-right-radius: 50%;

	mask-repeat: no-repeat;
	-webkit-mask-repeat: no-repeat;
	mask-size: 100% 100%;
	-webkit-mask-size: 100% 100%;
	mask-position: center;
	-webkit-mask-position: center;

	transition: --mask-inner-start, --mask-inner-end, --mask-outer-start, --mask-outer-end, opacity, transform;
	transition-duration: 0.35s;
	transition-timing-function: ease-in-out;

	opacity: 1;
	transform: translate(-50%, 0%) scale(1);
	--mask-inner-start: 20%;
	--mask-inner-end: 35%;
	--mask-outer-start: 45%;
	--mask-outer-end: 60%;

	mask-image: radial-gradient(
		ellipse at top,
		transparent var(--mask-inner-start),
		rgba(var(--nmd-tk-black-rgb), 1) var(--mask-inner-end),
		rgba(var(--nmd-tk-black-rgb), 1) var(--mask-outer-start),
		transparent var(--mask-outer-end)
	);
	-webkit-mask-image: radial-gradient(
		ellipse at top,
		transparent var(--mask-inner-start),
		rgba(var(--nmd-tk-black-rgb), 1) var(--mask-inner-end),
		rgba(var(--nmd-tk-black-rgb), 1) var(--mask-outer-start),
		transparent var(--mask-outer-end)
	);

	--lightray-top-x: 50%;
	--lightray-top-y: 0%;
	--lightray-top-opacity: 0.25;

	--lightray-mid-x: 50%;
	--lightray-mid-y: 0%;
	--lightray-mid-opacity: 0.25;

	--lightray-bot-x: 50%;
	--lightray-bot-y: 5%;
	--lightray-bot-opacity: 0.45;

	--lightray-shift: 0.5rem;

	animation: lightrays 7.5s ease-in-out infinite;

	background-image: conic-gradient(
		at var(--lightray-top-x) var(--lightray-top-y),
		transparent 98deg,
		var(--lightray-color) 101deg,
		transparent 103deg,
		transparent 105deg,
		var(--lightray-color) 110deg,
		transparent 112deg,
		transparent 115deg,
		var(--lightray-color) 117deg,
		transparent 120deg,
		transparent 130deg,
		var(--lightray-color) 133deg,
		transparent 135deg,
		transparent 147deg,
		var(--lightray-color) 149deg,
		transparent 154deg,
		transparent 169deg,
		var(--lightray-color) 171deg,
		transparent 177deg,
		transparent 189deg,
		var(--lightray-color) 193deg,
		transparent 195deg,
		transparent 212deg,
		var(--lightray-color) 215deg,
		transparent 217deg,
		transparent 225deg,
		var(--lightray-color) 230deg,
		transparent 237deg,
		transparent 240deg,
		var(--lightray-color) 242deg,
		transparent 246deg,
		transparent 250deg,
		var(--lightray-color) 254deg,
		transparent 255deg
	);
}

.lightrays:after {
	content: '';
	pointer-events: none;
	position: absolute;
	z-index: -1;

	top: 0%;
	left: 50%;
	width: 150%;
	height: 300%;

	border-bottom-left-radius: 50%;
	border-bottom-right-radius: 50%;

	mask-repeat: no-repeat;
	-webkit-mask-repeat: no-repeat;
	mask-size: 100% 100%;
	-webkit-mask-size: 100% 100%;
	mask-position: center;
	-webkit-mask-position: center;

	transition: --mask-inner-start, --mask-inner-end, --mask-outer-start, --mask-outer-end, opacity, transform;
	transition-duration: 0.15s;
	transition-timing-function: ease-in-out;

	opacity: 1;
	transform: translate(-50%, 0%) scale(1);
	--mask-inner-start: 20%;
	--mask-inner-end: 35%;
	--mask-outer-start: 45%;
	--mask-outer-end: 60%;

	mask-image: radial-gradient(
		ellipse at top,
		transparent var(--mask-inner-start),
		rgba(var(--nmd-tk-black-rgb), 1) var(--mask-inner-end),
		rgba(var(--nmd-tk-black-rgb), 1) var(--mask-outer-start),
		transparent var(--mask-outer-end)
	);
	-webkit-mask-image: radial-gradient(
		ellipse at top,
		transparent var(--mask-inner-start),
		rgba(var(--nmd-tk-black-rgb), 1) var(--mask-inner-end),
		rgba(var(--nmd-tk-black-rgb), 1) var(--mask-outer-start),
		transparent var(--mask-outer-end)
	);

	--lightray-top-x: 50%;
	--lightray-top-y: 0%;
	--lightray-top-opacity: 0.35;

	--lightray-mid-x: 50%;
	--lightray-mid-y: 0%;
	--lightray-mid-opacity: 0.25;

	--lightray-bot-x: 50%;
	--lightray-bot-y: 5%;
	--lightray-bot-opacity: 0.45;

	--lightray-shift: 0.5rem;

	animation: lightrays 5s ease-in-out infinite;

	background-image: conic-gradient(
			at var(--lightray-top-x) var(--lightray-top-y),
			transparent 98deg,
			var(--lightray-color) 101deg,
			transparent 103deg,
			transparent 105deg,
			var(--lightray-color) 110deg,
			transparent 112deg,
			transparent 115deg,
			var(--lightray-color) 117deg,
			transparent 120deg,
			transparent 130deg,
			var(--lightray-color) 133deg,
			transparent 135deg,
			transparent 147deg,
			var(--lightray-color) 149deg,
			transparent 154deg,
			transparent 169deg,
			var(--lightray-color) 171deg,
			transparent 177deg,
			transparent 189deg,
			var(--lightray-color) 193deg,
			transparent 195deg,
			transparent 212deg,
			var(--lightray-color) 215deg,
			transparent 217deg,
			transparent 225deg,
			var(--lightray-color) 230deg,
			transparent 237deg,
			transparent 240deg,
			var(--lightray-color) 242deg,
			transparent 246deg,
			transparent 250deg,
			var(--lightray-color) 254deg,
			transparent 255deg
		),
		conic-gradient(
			at var(--lightray-mid-x) var(--lightray-mid-y),
			transparent 103deg,
			var(--lightray-color) 105deg,
			transparent 110deg,
			transparent 112deg,
			var(--lightray-color) 115deg,
			transparent 117deg,
			transparent 120deg,
			var(--lightray-color) 130deg,
			transparent 133deg,
			transparent 135deg,
			var(--lightray-color) 147deg,
			transparent 149deg,
			transparent 154deg,
			var(--lightray-color) 169deg,
			transparent 171deg,
			transparent 177deg,
			var(--lightray-color) 189deg,
			transparent 193deg,
			transparent 195deg,
			var(--lightray-color) 212deg,
			transparent 215deg,
			transparent 217deg,
			var(--lightray-color) 225deg,
			transparent 230deg,
			transparent 237deg,
			var(--lightray-color) 240deg,
			transparent 242deg,
			transparent 246deg,
			var(--lightray-color) 250deg,
			transparent 254deg,
			transparent 255deg,
			var(--lightray-color) 259deg,
			transparent 262deg
		),
		conic-gradient(
			at var(--lightray-bot-x) var(--lightray-bot-y),
			transparent 100deg,
			var(--lightray-color) 103deg,
			transparent 105deg,
			transparent 110deg,
			var(--lightray-color) 112deg,
			transparent 115deg,
			transparent 117deg,
			var(--lightray-color) 120deg,
			transparent 130deg,
			transparent 133deg,
			var(--lightray-color) 135deg,
			transparent 147deg,
			transparent 149deg,
			var(--lightray-color) 154deg,
			transparent 169deg,
			transparent 171deg,
			var(--lightray-color) 177deg,
			transparent 189deg,
			transparent 193deg,
			var(--lightray-color) 195deg,
			transparent 212deg,
			transparent 215deg,
			var(--lightray-color) 217deg,
			transparent 225deg,
			transparent 230deg,
			var(--lightray-color) 237deg,
			transparent 240deg,
			transparent 242deg,
			var(--lightray-color) 246deg,
			transparent 250deg,
			transparent 254deg,
			var(--lightray-color) 255deg,
			transparent 259deg
		);
}

.lightrays {
	position: relative;
	z-index: 1;
	--lightray-color: rgba(var(--nmd-tk-white-rgb), 0.35);

	&-hover:before,
	&-hover:after {
		opacity: 0;
		transform: translate(-50%, -2%) scale(0.9);
		--mask-inner-start: 20%;
		--mask-inner-end: 20%;
		--mask-outer-start: 20%;
		--mask-outer-end: 20%;
	}

	&-hover:has(.lightrays-hover-trigger):has(.lightrays-hover-trigger:hover):before,
	&-hover:has(.lightrays-hover-trigger):has(.lightrays-hover-trigger:hover):after {
		opacity: 1;
		transform: translate(-50%, 0%) scale(1);
		--mask-inner-start: 20%;
		--mask-inner-end: 35%;
		--mask-outer-start: 45%;
		--mask-outer-end: 60%;
	}

	&-hover:not(:has(.lightrays-hover-trigger)):hover:before,
	&-hover:not(:has(.lightrays-hover-trigger)):hover:after {
		opacity: 1;
		transform: translate(-50%, 0%) scale(1);
		--mask-inner-start: 20%;
		--mask-inner-end: 35%;
		--mask-outer-start: 45%;
		--mask-outer-end: 60%;
	}
}

@keyframes lightrays {
	0%,
	100% {
		--lightray-top-x: 50%;
		--lightray-top-y: 0%;
		--lightray-top-opacity: 0.35;

		--lightray-mid-x: calc(50% + var(--lightray-shift));
		--lightray-mid-y: 0%;
		--lightray-mid-opacity: 0.25;

		--lightray-bot-x: 50%;
		--lightray-bot-y: 5%;
		--lightray-bot-opacity: 0.45;

		--mask-inner-end: 35%;
		--mask-outer-start: 45%;
		--mask-outer-end: 60%;
	}
	25% {
		--lightray-top-x: calc(50% + var(--lightray-shift));
		--lightray-bot-x: calc(50% - var(--lightray-shift));

		--lightray-mid-y: calc(2.5% - var(--lightray-shift));
	}
	33% {
		--lightray-top-opacity: 0.4;
		--lightray-top-opacity: 0.3;
		--lightray-bot-opacity: 0.35;

		--mask-inner-end: 30%;
		--mask-outer-start: 35%;
		--mask-outer-end: 55%;
	}
	50% {
		--lightray-top-y: calc(0% + var(--lightray-shift));
		--lightray-bot-y: calc(5% - var(--lightray-shift));

		--lightray-mid-x: calc(50% - var(--lightray-shift));
	}
	66% {
		--lightray-top-opacity: 0.4;
		--lightray-top-opacity: 0.45;
		--lightray-bot-opacity: 0.5;

		--mask-outer-start: 50%;
		--mask-outer-end: 65%;
	}
	75% {
		--lightray-top-x: calc(50% - var(--lightray-shift));
		--lightray-bot-x: calc(50% + var(--lightray-shift));

		--lightray-mid-y: calc(2.5% + var(--lightray-shift));
	}
}

.top_menu_toggle {
	transition: 0.25s ease-in-out;
	transform: rotate(180deg);
}

.top_menu_toggle.top_menu_opened {
	transform: rotate(0deg);
}

.top_menu {
	z-index: 1015;
	transition: 0.25s ease-in-out;

	display: block !important;
	overflow: visible !important;

	height: auto !important;
	max-height: calc(100vh - 120px + 1.5rem);
	/* min-height: calc((100vh - 120px) * 0.25); */

	border-bottom: 1px solid var(--bs-gray-500);

	/* Animated styles */
	box-shadow: 0px 0px 0px var(--bs-gray-500), 0px 0px 0px var(--nmd-tk-mid);
	border-radius: 0px;
	transform: translateY(calc(-100% + 1px));
}

.top_menu_content {
	height: auto !important;
	max-height: calc(100vh - 120px);
}

.top_menu.top_menu_opened {
	box-shadow: 0px 1px 0px var(--bs-gray-500), 0px 10px 10px var(--nmd-tk-mid);
	border-bottom-left-radius: 2rem;
	border-bottom-right-radius: 2rem;
	transform: translateY(0px);
}

/* Prevent the top menu from blocking clicks when closed */
.top_navbar.top_navbar_closed {
	pointer-events: none;
}

/* Allow the top menu to receive clicks when closed */
.top_navbar.top_navbar_opened {
	pointer-events: initial;
}

/* Always allow the top menu buttons to receive clicks */
.top_navbar_buttons {
	pointer-events: initial !important;
}

html,
html * {
	scroll-behavior: smooth;
}

@media screen and (max-width: 768px) {
}

@media (min-width: 320px) {
	/* smartphones, iPhone, portrait 480x320 phones */
}
@media (min-width: 481px) {
	/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width: 641px) {
	/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (min-width: 961px) {
	/* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
	/* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
	/* hi-res laptops and desktops */
}

html,
body {
	height: 100%;
	width: 100%;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	width: 100vw;
	height: 100%;
	min-height: 100%;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

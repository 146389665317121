.projectSpotlightSwitch_light {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, -50%);
}

.projectSpotlightSwitch_light {
	color: rgba(var(--nmd-tk-body-color-rgb), 0.25);
	-webkit-text-fill-color: rgba(var(--nmd-tk-body-color-rgb), 0.25);
}

.projectSpotlightSwitch:hover .projectSpotlightSwitch_light {
	color: rgba(var(--nmd-tk-warning-rgb), 0.85);
	-webkit-text-fill-color: rgba(var(--nmd-tk-warning-rgb), 0.85);
}

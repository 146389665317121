.Cryptex_Dial {
	transition: all 0.55s var(--timing-func-ease-out-soft);

	--start: 5%;
	--buffer: 0px;
	--end: calc((100% / var(--visible-options) * var(--overflow-options)) - var(--buffer));

	mask-image: linear-gradient(
		to bottom,
		rgba(var(--nmd-tk-black-rgb), 0) var(--start),
		rgba(var(--nmd-tk-black-rgb), 0.1) calc(var(--end)),
		rgba(var(--nmd-tk-black-rgb), 1) calc(var(--end) + var(--buffer) + 10px),
		rgba(var(--nmd-tk-black-rgb), 1) calc(100% - (var(--end) + (var(--buffer) + 10px))),
		rgba(var(--nmd-tk-black-rgb), 0.1) calc(100% - var(--end)),
		rgba(var(--nmd-tk-black-rgb), 0) calc(100% - var(--start))
	);
	-webkit-mask-image: linear-gradient(
		to bottom,
		rgba(var(--nmd-tk-black-rgb), 0) var(--start),
		rgba(var(--nmd-tk-black-rgb), 0.1) calc(var(--end)),
		rgba(var(--nmd-tk-black-rgb), 1) calc(var(--end) + var(--buffer) + 10px),
		rgba(var(--nmd-tk-black-rgb), 1) calc(100% - (var(--end) + (var(--buffer) + 10px))),
		rgba(var(--nmd-tk-black-rgb), 0.1) calc(100% - var(--end)),
		rgba(var(--nmd-tk-black-rgb), 0) calc(100% - var(--start))
	);
}

.Cryptex_Dial_Col {
	transition: all 0.25s var(--timing-func-ease-out-soft);
	outline: 1px solid rgba(var(--nmd-tk-info-rgb), 0);
	outline-offset: 0rem;

	border-radius: calc(var(--size) * 0.05);
}

.Cryptex_Dial_Col.focusedDial {
	outline: 2px solid rgba(var(--nmd-tk-info-rgb), 0.25);
	outline-offset: calc(1rem - 1px);

	border-radius: calc(var(--size) * 0.1);
}

.Cryptex_Dial_Options {
	transition: all calc(var(--transitionMs) / 1000 * 1s) var(--timing-func-ease-out-soft);
}

.Cryptex_Dial_Options_Text {
	transition: all calc(var(--transitionMs) / 1000 * 1s) var(--timing-func-ease-out-soft);
}

.Cryptex_Dial_Selection {
	transition: all 1.5s var(--timing-func-ease-out-soft);

	--shadow-color: var(--nmd-tk-black);
	box-shadow: inset 0px 0px calc(var(--size) * 0.1) var(--shadow-color);
}

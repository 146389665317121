.ProjectSpotlight_Card:not(.noSkills) {
	--mask-position: calc(100% - 5rem) 5rem;
	--mask-start: 8.5;
	--mask-end: 11;
	--mask-url: radial-gradient(circle 7.5rem at var(--mask-position), rgba(var(--nmd-tk-black-rgb), 0) calc(var(--mask-start) * 1rem), rgba(var(--nmd-tk-black-rgb), 1) calc(var(--mask-end) * 1rem));
	mask: var(--mask-url);
	-webkit-mask: var(--mask-url);
}

.ProjectSpotlight_Card {
	--border-radius: 15rem;
}

.ProjectSpotlight_Card.fromRight {
	--mask-position: 5rem 5rem;

	border-top-right-radius: var(--border-radius) !important;
	border-bottom-left-radius: var(--border-radius) !important;
}

.ProjectSpotlight_Card:not(.fromRight) {
	--mask-position: calc(100% - 5rem) 5rem;

	border-top-left-radius: var(--border-radius) !important;
	border-bottom-right-radius: var(--border-radius) !important;
}

.login_Button {
	width: min(80vw, 500px) !important;
}

.login_Logo {
	max-width: 100%;
	max-height: 100%;
	width: unset;
	height: unset;
	position: relative;
	animation: login_Logo_In 3s ease 1, login_Logo_Hover 3s ease-in-out 3s infinite, login_Logo_Spin 10s ease 10s infinite;
}

@keyframes login_Logo_In {
	0% {
		top: -50vh;
		transform: rotateX(-90deg) scale(0.25);
	}
	25% {
		top: 10px;
		transform: rotateX(0deg) scale(1);
	}
	50% {
		top: -20px;
	}
	100% {
		top: 0px;
	}
}

@keyframes login_Logo_Hover {
	0%,
	100% {
		top: 0px;
	}
	50% {
		top: -20px;
	}
}

@keyframes login_Logo_Spin {
	0% {
		transform: rotateY(0deg) scale(1);
	}
	1% {
		transform: rotateY(-360deg) scale(1);
	}
	2% {
		transform: rotateY(-720deg) scaleX(0.75) scaleY(1.05);
	}
	5% {
		transform: rotateY(-1080deg) scale(1);
	}
	100% {
		transform: rotateY(-1080deg) scale(1);
	}
}

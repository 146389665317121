:root,
html {
	touch-action: pan-x pan-y;
	touch-action: none;
	height: 100%;
}

html,
body.overscroll-behavior-y-contain,
.overscroll-behavior-y-contain {
	overscroll-behavior-y: contain;
}

.btn,
.btn:hover,
.btn:focus {
	outline: none !important;
	box-shadow: none !important;
}

.focus-ring-none {
	--nmd-tk-focus-ring-width: 0;
	--nmd-tk-focus-ring-opacity: 0;
}

@media (hover: none), (pointer: coarse) {
	/* Touch devices */
}

@media not ((hover: none) or (pointer: coarse)) {
	/* Not touch devices */
}

.navbar-toggler {
	outline: none !important;
	box-shadow: none !important;
	border: none !important;
}

.form-switch {
	cursor: pointer;
	padding-left: 2rem !important;
}

.form-switch .form-check-input {
	cursor: pointer;
	margin-left: -2rem !important;
}

.input-group .form-range {
	width: auto !important;
	height: auto !important;
	padding: 0.25rem !important;
	flex: 1 1 auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	appearance: textfield;
	-moz-appearance: textfield;
	-webkit-appearance: textfield;
}

.accordion-button {
	--nmd-tk-accordion-btn-icon-color: var(--nmd-tk-accordion-btn-color);
	padding-right: 2.5rem !important;
}

.accordion-button::after {
	position: absolute;
	right: 0.25rem;
	background-image: none !important;
	background: var(--nmd-tk-accordion-btn-icon-color);
	mask-image: var(--nmd-tk-accordion-btn-active-icon);
	-webkit-mask-image: var(--nmd-tk-accordion-btn-active-icon);
}

.accordion-collapse.collapse:not(.show):not(.collapsing) {
	display: block !important;
	height: 0 !important;
	pointer-events: none;
	overflow: hidden !important;
}

[data-toggle='buttons'] input[type='checkbox']:checked ~ i.check,
[data-toggle='buttons'] input[type='checkbox']:not(:checked) ~ i.uncheck {
	display: inline-block;
}

[data-toggle='buttons'] input[type='checkbox']:checked ~ i.uncheck,
[data-toggle='buttons'] input[type='checkbox']:not(:checked) ~ i.check {
	display: none;
}

.overflow-y-auto,
.overflow-x-auto {
	scrollbar-gutter: stable;
}

/* Scrollbar overrides */
*::-webkit-scrollbar {
	width: 0.5rem;
	height: 0.5rem;
	background-color: var(--nmd-tk-body-bg);
}

.scrollbar-sm::-webkit-scrollbar,
.scrollbar-sm *::-webkit-scrollbar {
	width: 0.25rem;
	height: 0.25rem;
	background-color: var(--nmd-tk-body-bg);
}

*::-webkit-scrollbar-track {
	box-shadow: inset 0 0 0.125rem rgba(0, 0, 0, 0.75);
	background-color: var(--nmd-tk-body-bg);
	border-radius: 50rem;
}

*::-webkit-scrollbar-thumb {
	/* background-color: rgba(56, 61, 65, 0.3); */
	/* box-shadow: inset 0 0 0.25rem rgba(255, 255, 255, 0.5), inset 0 0 0.1rem rgba(0, 0, 0, 0.75); */
	background-color: var(--nmd-tk-dark);
	border-radius: 50rem;
	border: var(--nmd-tk-border-width) var(--nmd-tk-border-style) rgba(var(--nmd-tk-border-rgb), 0.2);
}

.scrollbar-none::-webkit-scrollbar {
	width: 0rem;
	height: 0rem;
	-webkit-appearance: none;
	display: none;
	background: transparent;
}

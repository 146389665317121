.LinkButton {
	transition: all 0.25s ease-in-out;

	--color-rgb: var(--nmd-tk-white-rgb);
	--color-opacity: 1;

	--background-rgb: var(--nmd-tk-primary-rgb);
	--background-opacity: 0.75;

	--nmd-tk-btn-color: rgba(var(--color-rgb), var(--color-opacity));
	--nmd-tk-btn-bg: rgba(var(--background-rgb), var(--background-opacity));

	--nmd-tk-btn-active-color: rgba(var(--color-rgb), var(--color-opacity));
	--nmd-tk-btn-active-bg: rgba(var(--background-rgb), var(--background-opacity));

	--nmd-tk-btn-hover-color: rgba(var(--color-rgb), 1);
	--nmd-tk-btn-hover-bg: rgba(var(--background-rgb), 1);

	outline: 0.2rem solid rgba(var(--background-rgb), 1) !important;
	outline-offset: -0.1rem !important;

	margin: 0.2rem;
}

.LinkButton:not([data-href]) {
	--background-opacity: 1;
}

.LinkButton:hover,
.LinkButton:focus {
	outline: 0.2rem solid rgba(var(--background-rgb), 1) !important;
	/* outline-offset: 0.25rem !important; */
}

.Headshot {
	--transition-timing-function: var(--timing-func-spring-out);
	--transition-duration: 1s;

	--rotateZ: -5deg;
	--background-opacity: 0.75;

	--bubble-bg: rgba(var(--nmd-tk-white-rgb), 1);
}
.Headshot.hover:hover {
	--rotateZ: 5deg;
	--background-opacity: 0.6;
	--background-color-rgb: var(--nmd-tk-white-rgb);
}

.Headshot:before {
	content: var(--bubble-content, 'Thanks for\Astopping by!');
	white-space: pre;
	transition: all 0.75s var(--transition-timing-function);
	pointer-events: none;
	z-index: -2;
	overflow: hidden;

	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;

	width: 16rem;
	height: 5rem;
	text-align: center;
	font-size: 1.75rem;
	line-height: 1.5rem;
	font-family: OdinRounded, sans-serif;
	padding: 1rem 3rem 1rem 1.5rem;
	border-radius: 50rem;
	color: var(--nmd-tk-dark);
	background: var(--bubble-bg);

	--shadow-pop-light-rgb: var(--nmd-tk-light-rgb);
	--shadow-pop-shadow-rgb: var(--nmd-tk-dark-rgb);

	--shadow-pop-width: 2px;
	--shadow-pop-opacity: 0.85;

	--shadow-pop-width-current: var(--shadow-pop-width);
	--shadow-pop-opacity-current: var(--shadow-pop-opacity);

	box-shadow: calc(var(--shadow-pop-width-current) * -1) calc(var(--shadow-pop-width-current) * -1) calc(var(--shadow-pop-width-current) / 2)
			rgba(var(--shadow-pop-light-rgb), var(--shadow-pop-opacity-current)),
		calc(var(--shadow-pop-width-current) * -1) var(--shadow-pop-width-current) calc(var(--shadow-pop-width-current) / 2) rgba(var(--shadow-pop-shadow-rgb), var(--shadow-pop-opacity-current));

	top: 50%;
	left: 25%;

	transform: scale(0) rotateZ(-90deg) translateY(-50%);

	opacity: 0;
}

.Headshot:after {
	content: '';
	transition: all 0.75s var(--transition-timing-function);
	pointer-events: none;
	z-index: -1;

	position: fixed;
	display: block;

	width: 0px;
	height: 0px;

	--triangle-size: 1rem;
	border-top: calc(var(--triangle-size) * 3) solid var(--bubble-bg);
	border-right: calc(var(--triangle-size) * 1) solid transparent;
	border-left: calc(var(--triangle-size) * 1.75) solid var(--bubble-bg);
	border-bottom: calc(var(--triangle-size) * 1.5) solid transparent;

	border-bottom-left-radius: 100%;

	top: 50%;
	left: 50%;

	transform: scale(0) rotateZ(-90deg) translateY(0%);

	opacity: 0;
}

@media (min-width: 1025px) {
	.Headshot.hover:hover:before {
		top: 32%;
		left: -40%;

		transform: scale(1) rotateZ(-22deg) translateY(0%);

		opacity: 1;
		color: var(--nmd-tk-dark);
	}

	.Headshot.hover:hover:after {
		top: 45.5%;
		left: 12%;

		transform: scale(1) rotateZ(-33deg) translateY(0%);

		opacity: 1;
	}
}

@media (max-width: 1024px) {
	.Headshot:before {
		z-index: 1;
		padding: 0.75rem 1.5rem;
		width: 225px;
		font-size: 1.5rem;
		line-height: 1.25rem;
	}

	.Headshot.hover:hover:before {
		top: 77%;
		left: 52%;

		transform: scale(1) rotateZ(-10deg) translateX(-50%);

		opacity: 1;
	}

	.Headshot:after {
		z-index: 2;
		--triangle-size: 0.65rem;

		top: 60%;
		left: 50%;

		transform: scale(0) rotateZ(90deg) translateY(0%);

		opacity: 0;
	}

	.Headshot.hover:hover:after {
		top: 70%;
		left: 52%;

		transform: scale(1) rotateZ(169deg) translateY(0%);

		opacity: 1;
	}
}

.Headshot img {
	--transition-timing-function: ease-in-out;
	--transition-duration: 0.35s;

	--translateY: 1.75rem;
	--scaleX: 0.85;
	--scaleY: 0.85;
}
.Headshot.hover:hover img {
	--translateY: 0.5rem;
	--scaleX: 1;
	--scaleY: 1;
}

.Headshot img.Headshot_GoT {
	--mask-size: 0% 0%;
	--mask-start: 90% 75%;
	mask: radial-gradient(#000 70%, #0000 71%) content-box var(--mask-start) / var(--mask-size) no-repeat, linear-gradient(#000 0 0);
	-webkit-mask: radial-gradient(#000 70%, #0000 71%) content-box var(--mask-start) / var(--mask-size) no-repeat, linear-gradient(#000 0 0);
	mask-composite: exclude;
	-webkit-mask-composite: xor;
}

.Headshot.hover img.Headshot_GoT {
	--mask-size: 100% 100%;
}

.PanelMenu {
	--top-buffer: calc(56px + 1.5rem);
	--bottom-buffer: calc(56px + 1.5rem);
	--max-height: calc(calc(var(--content-height) * 1px) - var(--top-buffer) - var(--bottom-buffer));
	transition: 0.5s ease-in-out;

	max-height: var(--max-height);
	--bottom: var(--bottom-buffer);
	--right: 0px;
}

.PanelMenu .PanelMenu_Content {
	max-height: var(--max-height);

	--translateX: 100%;
	--rotateY: 90deg;
	transition: 0.5s ease-in-out;

	box-shadow: 0;
}

.PanelMenu.open .PanelMenu_Content {
	--translateX: 0px;
	--rotateY: 0deg;

	box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
}

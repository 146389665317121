.FloatingZone {
	--edge-buffer: 0.5rem;
	--edge-outline-width: 3px;
}

.FloatingZone::before {
	content: '';
	position: absolute;
	transition: all 0.1s ease-in-out;

	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;

	opacity: 0;

	border: calc(var(--edge-outline-width) * 0) rgba(var(--nmd-tk-dark-rgb), 0) solid;
	box-shadow: 0px 0px 0px rgba(var(--nmd-tk-dark-rgb), 0) inset;

	outline: 0rem var(--nmd-tk-primary) solid;
	outline-offset: calc(var(--edge-outline-width) * 1);
	background: rgba(var(--nmd-tk-light-rgb), 0.01);
	backdrop-filter: blur(0px);
	border-radius: 0.25rem;
}

.FloatingZone.holding::before {
	content: '';
	position: absolute;
	transition: all 0.1s ease-in-out;

	top: var(--edge-buffer);
	bottom: var(--edge-buffer);
	left: var(--edge-buffer);
	right: var(--edge-buffer);

	opacity: 1;

	border: calc(var(--edge-outline-width) * 3) rgba(var(--nmd-tk-dark-rgb), 0.25) solid;
	box-shadow: 0px 0px calc(var(--edge-outline-width) * 15) rgba(var(--nmd-tk-light-rgb), 0.1) inset;

	outline: var(--edge-outline-width) var(--nmd-tk-primary) dashed;
	outline-offset: calc(var(--edge-outline-width) * 1);
	background: rgba(var(--nmd-tk-light-rgb), 0.01);
	backdrop-filter: blur(2px);
	border-radius: 1.25rem;
}

.FloatingContent.holding > * {
	outline: var(--edge-outline-width) rgba(var(--nmd-tk-dark-rgb), 0.75) solid !important;
	box-shadow: 0px 0px calc(var(--edge-outline-width) * 5) rgba(var(--nmd-tk-light-rgb), 0.25) !important;
}

.FloatingZone .FloatingZoneInfo {
	transition: all 0.1s ease-in-out;
	opacity: 0;
}

.FloatingZone.holding .FloatingZoneInfo {
	opacity: 0.35;
}

/* .FloatingContent {
	transition: all 0.1s ease;
} */

.BioQuote {
	position: relative;
}

.BioQuote_Open,
.BioQuote_Close {
	transition: all 1s 0.25s ease-in-out !important;
	position: absolute;
	z-index: 1;

	font-size: 6rem;
	line-height: 6rem;
	color: var(--nmd-tk-pink);

	width: 3rem;
	height: 2rem;

	overflow: hidden;
}

.BioQuote_Open {
	top: -0.75rem;
	left: -0.75rem;
}

.BioQuote_Close {
	bottom: calc(4rem + 0.75rem);
	right: -1.5rem;
}

.BioQuote.cleanBioQuote * {
	transition: all 1s 0.25s ease-in-out !important;
}
.BioQuote.cleanBioQuote,
.BioQuote.cleanBioQuote .BioQuote_Open,
.BioQuote.cleanBioQuote .BioQuote_Close {
	color: var(--nmd-tk-card-color);
}

.BioQuote.cleanBioQuote .BioQuote_None:before,
.BioQuote.cleanBioQuote .BioQuote_BO:before,
.BioQuote.cleanBioQuote .BioQuote_Master:before,
.BioQuote.cleanBioQuote .BioQuote_Master:after {
	opacity: 0;
}

.BioQuote.cleanBioQuote .BioQuote_None,
.BioQuote.cleanBioQuote .BioQuote_BO,
.BioQuote.cleanBioQuote .BioQuote_Master,
.BioQuote.cleanBioQuote .BioQuote_Master {
	color: rgba(var(--nmd-tk-white-rgb), 1);
	text-decoration: none;
}

.BioQuote_Master {
	--transition: all 0.35s var(--timing-func-windup-in-out);
	transition: var(--transition);
	position: relative;

	color: rgba(var(--nmd-tk-white-rgb), 0.01);

	--shadow-size: 0.25rem;
	--shadow-blur: calc(var(--shadow-size) / 2);
	text-shadow: 0px 0px 0px rgba(var(--nmd-tk-warning-rgb), 0);
}
.BioQuote_Master:hover {
	color: rgba(var(--nmd-tk-white-rgb), 1);
	animation: MasterGlow 3s 0.35s var(--timing-func-spring-out-soft) infinite;
}

.BioQuote_Master:before {
	pointer-events: none;
	--transition: all 0.35s var(--timing-func-windup-in-out);
	transition: var(--transition);
	content: 'an expert';
	position: absolute;
	z-index: 2;

	font-family: var(--brand-font);
	letter-spacing: -1px;
	word-spacing: -0.25rem;
	color: rgba(var(--nmd-tk-warning-rgb), 1);
	transform: rotateZ(0deg) translate(-0.25rem, 0rem);

	opacity: 1;
}
.BioQuote_Master:hover:before {
	opacity: 0;
	transform: rotateZ(0deg) translate(-0.5rem, 1rem);
}

@keyframes MasterGlow {
	0%,
	100% {
		text-shadow: 0px 0px 0px rgba(var(--nmd-tk-warning-rgb), 0.75);
	}

	7.5% {
		text-shadow: 0px calc(var(--shadow-size) * -1) var(--shadow-blur) rgba(var(--nmd-tk-warning-rgb), 0.75);
	}

	25% {
		text-shadow: var(--shadow-size) calc(var(--shadow-size) * -1) var(--shadow-blur) rgba(var(--nmd-tk-warning-rgb), 0.75);
	}

	50% {
		text-shadow: var(--shadow-size) var(--shadow-size) var(--shadow-blur) rgba(var(--nmd-tk-warning-rgb), 0.75);
	}

	65% {
		text-shadow: calc(var(--shadow-size) * -1) var(--shadow-size) var(--shadow-blur) rgba(var(--nmd-tk-warning-rgb), 0.75);
	}

	80% {
		text-shadow: calc(var(--shadow-size) * -1) calc(var(--shadow-size) * -1) var(--shadow-blur) rgba(var(--nmd-tk-warning-rgb), 0.75);
	}
}

.BioQuote_Master:after {
	pointer-events: none;
	--transition: all 1s ease-in-out;
	transition: var(--transition);

	font-family: 'Material Symbols Outlined';
	font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
	font-style: normal;

	font-size: 1.75rem;
	content: '\f454';
	color: rgba(var(--nmd-tk-warning-rgb), 1);

	text-shadow: -1px 3px 4px var(--nmd-tk-body-bg);

	position: absolute;
	z-index: 1;
	top: 1.4rem;
	left: 3.77rem;
	transform: translateX(-50%) rotateZ(7deg);
}

.BioQuote_Master:hover:after {
	animation: MasterFlag 3s 0.35s var(--timing-func-spring-out-soft) infinite;
}

@keyframes MasterFlag {
	0% {
		transform: translateX(-50%) rotateZ(7deg);
		top: 1.4rem;
		left: 3.77rem;
	}

	7.5% {
		transform: translateX(-50%) rotateZ(-92.5deg);
		top: 1.65rem;
		left: 4rem;
	}

	25% {
		transform: translateX(-50%) rotateZ(-42.5deg);
		top: 1.65rem;
		left: -0.75rem;
	}

	50% {
		transform: translateX(-50%) rotateZ(52deg);
		top: -0.85rem;
		left: -0.75rem;
	}

	65% {
		transform: translateX(-50%) rotateZ(135deg);
		top: -1.5rem;
		left: calc(100% + 1rem);
	}

	80% {
		transform: translateX(-50%) rotateZ(236deg);
		top: 1.5rem;
		left: calc(100% + 0.75rem);
	}

	100% {
		transform: translateX(-50%) rotateZ(373deg);
		top: 1.4rem;
		left: 3.77rem;
	}
}

.BioQuote_None {
	--transition: all 0.35s var(--timing-func-windup-in-out);
	transition: var(--transition);
	position: relative;
	color: rgba(var(--nmd-tk-white-rgb), 0.01);
	text-decoration: rgba(var(--nmd-tk-pink-rgb), 0.01) solid line-through;
}
.BioQuote_None:hover {
	color: rgba(var(--nmd-tk-white-rgb), 1);
	text-decoration: var(--nmd-tk-pink) solid line-through;
}

.BioQuote_None:before {
	transition: var(--transition);
	content: 'some,';
	position: absolute;
	z-index: 1;
	pointer-events: none;

	font-family: var(--brand-font);
	color: var(--nmd-tk-pink);
	transform: rotateZ(0deg) translate(0%, 0%);
}
.BioQuote_None:hover:before {
	transform: rotateZ(10deg) translate(15%, -43%);
}

.BioQuote_BO {
	--transition: all 1.25s var(--timing-func-spring-out);
	transition: var(--transition);
	position: relative;
	color: rgba(var(--nmd-tk-white-rgb), 0.01);
	text-decoration: rgba(var(--nmd-tk-pink-rgb), 0.01) solid line-through;
}
.BioQuote_BO:hover {
	color: rgba(var(--nmd-tk-white-rgb), 1);
	text-decoration: var(--nmd-tk-pink) solid line-through;
}

.BioQuote_BO:before {
	transition: var(--transition);
	content: 'invariably';
	position: absolute;
	z-index: 1;
	pointer-events: none;

	font-family: var(--brand-font);
	letter-spacing: 2px;
	color: var(--nmd-tk-pink);
	right: 50%;
	transform: rotateZ(0deg) translate(50%, 0%);
}
.BioQuote_BO:hover:before {
	right: 50%;
	transform: rotateZ(87deg) translate(57%, 27%);
}

.BioQuote_Me {
	text-decoration: var(--nmd-tk-teal) solid line-through;
}
.BioQuote_Me:before {
	content: 'Doubt it';
	position: absolute;
	z-index: 1;

	font-family: var(--brand-font);
	color: var(--nmd-tk-teal);
	right: 0px;
	top: 10%;
	transform: rotateZ(-14deg) translate(60%, 75%);
}

.BioQuote_WS:before {
	content: 'Maybe';
	position: absolute;
	z-index: 1;

	font-family: var(--brand-font);
	color: var(--nmd-tk-pink);
	right: 0px;
	top: 50%;
	transform: rotateZ(-15deg) translate(115%, 15%);
}

.BioQuote_BF:before {
	content: 'Probably';
	position: absolute;
	z-index: 1;

	font-family: var(--brand-font);
	color: var(--nmd-tk-pink);
	right: 0px;
	top: 0px;
	transform: rotateZ(15deg) translate(110%, -96%);
}

.BioQuote_WK:before {
	content: '¯\\_(ツ)_/¯';
	position: absolute;
	z-index: 1;

	/* font-family: var(--brand-font); */
	color: var(--nmd-tk-pink);
	right: 0px;
	top: 0px;
	transform: rotateZ(5deg) translate(110%, -25%);
}

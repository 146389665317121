.ProjectRoadmap {
	--section-size: 3rem;

	--section-offset: calc(var(--section-size) - calc(var(--border-width)));

	--border-radius: calc(var(--section-size) * 1);

	--border-width: 0.5rem;
	--border-style: var(--border-width) double rgba(var(--nmd-tk-warning-rgb), 0.5);

	--shadow-size: calc(var(--section-size) / 6);
	--shadow-size-negative: calc(var(--shadow-size) * -1);

	--shadow-inner-left-bottom: var(--shadow-size) var(--shadow-size-negative) 0px var(--shadow-size) var(--nmd-tk-dark) inset;
	--shadow-outer-left-bottom: var(--shadow-size-negative) var(--shadow-size) 0px var(--shadow-size) var(--nmd-tk-dark);

	--shadow-inner-left-top: var(--shadow-size) var(--shadow-size) 0px var(--shadow-size) var(--nmd-tk-dark) inset;
	--shadow-outer-left-top: var(--shadow-size-negative) var(--shadow-size-negative) 0px var(--shadow-size) var(--nmd-tk-dark);

	--shadow-inner-right-top: var(--shadow-size-negative) var(--shadow-size) 0px var(--shadow-size) var(--nmd-tk-dark) inset;
	--shadow-outer-right-top: var(--shadow-size) var(--shadow-size-negative) 0px var(--shadow-size) var(--nmd-tk-dark);

	--shadow-inner-right-bottom: var(--shadow-size-negative) var(--shadow-size-negative) 0px var(--shadow-size) var(--nmd-tk-dark) inset;
	--shadow-outer-right-bottom: var(--shadow-size) var(--shadow-size) 0px var(--shadow-size) var(--nmd-tk-dark);

	--shadow-inner-bottom: 0px 0px 0px var(--shadow-size) var(--nmd-tk-dark) inset;
	--shadow-outer-bottom: 0px calc(var(--shadow-size) * 2) 0px 0px var(--nmd-tk-dark);
}

.ProjectRoadmap:not(.swerve) .ProjectRoadmap_Title {
	position: relative;

	margin-bottom: var(--section-offset);
}

.ProjectRoadmap.swerve .ProjectRoadmap_Title {
	position: relative;

	margin-bottom: calc(var(--section-offset) * 2.5);
}

.ProjectRoadmap_Title:before {
	content: ' ';
	position: absolute;
	height: var(--section-size);
	top: 100%;
	left: calc(50% - calc(var(--border-width) / 2));
	right: 0px;

	border-bottom-left-radius: var(--border-radius);
	border-left: var(--border-style);
	border-bottom: var(--border-style);

	box-shadow: var(--shadow-outer-left-bottom), var(--shadow-inner-left-bottom);
}

.ProjectRoadmap_Complete {
	position: relative;

	margin-top: calc(var(--section-offset) * 3.65);
}

.ProjectRoadmap_Complete:nth-child(even):before {
	content: ' ';
	position: absolute;
	height: var(--section-size);
	top: calc(var(--section-size) * -1);
	left: calc(50% - calc(var(--border-width) / 2));
	right: 0px;

	border-top-left-radius: var(--border-radius);
	border-left: var(--border-style);
	border-top: var(--border-style);

	box-shadow: var(--shadow-outer-left-top), var(--shadow-inner-left-top);
}

.ProjectRoadmap_Complete:nth-child(odd):before {
	content: ' ';
	position: absolute;
	height: var(--section-size);
	top: calc(var(--section-size) * -1);
	left: 0px;
	right: calc(50% - calc(var(--border-width) / 2));

	border-top-right-radius: var(--border-radius);
	border-right: var(--border-style);
	border-top: var(--border-style);

	box-shadow: var(--shadow-outer-right-top), var(--shadow-inner-right-top);
}

.ProjectRoadmap:not(.swerve) .ProjectRoadmap_Milestone {
	position: relative;

	padding-top: var(--section-offset);
	margin-bottom: var(--section-offset);
}

.ProjectRoadmap.swerve .ProjectRoadmap_Milestone {
	position: relative;

	padding-top: var(--section-offset);
	margin-bottom: calc(var(--section-offset) * 2.5);
}

.ProjectRoadmap:not(.swerve) .ProjectRoadmap_Milestone:nth-child(even):before {
	content: ' ';
	position: absolute;
	height: var(--section-size);
	top: 100%;
	left: 0px;
	right: 50%;

	border-bottom-right-radius: var(--border-radius);
	border-right: var(--border-style);
	border-bottom: var(--border-style);

	box-shadow: var(--shadow-outer-right-bottom), var(--shadow-inner-right-bottom);
}

.ProjectRoadmap:not(.swerve) .ProjectRoadmap_Milestone:nth-child(even):after {
	content: ' ';
	position: absolute;
	height: calc(var(--shadow-size) * 2 + var(--border-width));
	top: 50%;
	left: 50%;
	right: 0px;

	border-bottom: var(--border-style);

	box-shadow: var(--shadow-inner-bottom), var(--shadow-outer-bottom);
}

.ProjectRoadmap:not(.swerve) .ProjectRoadmap_Milestone:nth-child(odd):before {
	content: ' ';
	position: absolute;
	height: var(--section-size);
	top: 100%;
	left: 50%;
	right: 0px;

	border-bottom-left-radius: var(--border-radius);
	border-left: var(--border-style);
	border-bottom: var(--border-style);

	box-shadow: var(--shadow-outer-left-bottom), var(--shadow-inner-left-bottom);
}

.ProjectRoadmap:not(.swerve) .ProjectRoadmap_Milestone:nth-child(odd):after {
	content: ' ';
	position: absolute;
	height: calc(var(--shadow-size) * 2 + var(--border-width));
	top: 50%;
	left: 0px;
	right: 50%;

	border-bottom: var(--border-style);

	box-shadow: var(--shadow-inner-bottom), var(--shadow-outer-bottom);
}

/* .ProjectRoadmap_EndCap:after {
	content: ' ';
	position: absolute;
	height: calc(var(--section-size) + var(--shadow-size) * 2 + var(--border-width) / 2);

	top: calc(var(--border-width) / 2 * -1);

	left: calc(100% - calc(var(--section-size) * 2));
	right: calc(var(--section-size) / 2);

	border-bottom-right-radius: var(--border-radius);
	border-top-right-radius: var(--border-radius);

	border-top: var(--border-style);
	border-right: var(--border-style);
	border-bottom: var(--border-style);

	box-shadow: var(--shadow-inner-right), var(--shadow-outer-right);
} */

.ProjectRoadmap.swerve .ProjectRoadmap_Milestone:nth-child(even):before {
	content: ' ';
	position: absolute;
	height: var(--section-size);
	top: 0px;
	left: calc(50% - calc(var(--border-width) / 2));
	right: 0px;

	border-top-left-radius: var(--border-radius);
	border-left: var(--border-style);
	border-top: var(--border-style);

	box-shadow: var(--shadow-outer-left-top), var(--shadow-inner-left-top);
}

.ProjectRoadmap.swerve .ProjectRoadmap_Milestone:nth-child(even):after {
	content: ' ';
	position: absolute;
	height: var(--section-size);
	top: 100%;
	left: 0px;
	right: calc(50% - calc(var(--border-width) / 2));

	border-bottom-right-radius: var(--border-radius);
	border-right: var(--border-style);
	border-bottom: var(--border-style);

	box-shadow: var(--shadow-outer-right-bottom), var(--shadow-inner-right-bottom);
}

.ProjectRoadmap.swerve .ProjectRoadmap_Milestone:nth-child(odd):before {
	content: ' ';
	position: absolute;
	height: var(--section-size);
	top: 0px;
	left: 0px;
	right: calc(50% - calc(var(--border-width) / 2));

	border-top-right-radius: var(--border-radius);
	border-right: var(--border-style);
	border-top: var(--border-style);

	box-shadow: var(--shadow-outer-right-top), var(--shadow-inner-right-top);
}

.ProjectRoadmap.swerve .ProjectRoadmap_Milestone:nth-child(odd):after {
	content: ' ';
	position: absolute;
	height: var(--section-size);
	top: 100%;
	left: calc(50% - calc(var(--border-width) / 2));
	right: 0px;

	border-bottom-left-radius: var(--border-radius);
	border-left: var(--border-style);
	border-bottom: var(--border-style);

	box-shadow: var(--shadow-outer-left-bottom), var(--shadow-inner-left-bottom);
}

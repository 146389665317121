.SkillGroup {
	transition: all 0.35s var(--timing-func-spring-out-soft);
	outline: 1px solid rgba(var(--nmd-tk-warning-rgb), 0);
	outline-offset: 2rem;
	border-radius: var(--nmd-tk-border-radius-xl);

	animation: SkillGroup_Outline 0.5s linear infinite alternate;
}

.SkillGroup_Label:hover ~ .SkillGroup {
	outline: 1px solid rgba(var(--nmd-tk-warning-rgb), 0.75);
	outline-offset: -1px;
	border-radius: var(--nmd-tk-border-radius-xxl);
}

.SkillGroup_Label {
	transition: all 0.35s var(--timing-func-spring-out-soft);
}

.SkillGroup_Label:hover {
	color: rgba(var(--nmd-tk-warning-rgb), 1);
}

.SkillGroup_Label hr {
	transition: all 0.35s var(--timing-func-spring-out-soft);
	opacity: 1;
}

.SkillGroup_Label:hover hr {
	opacity: 0.5;
	width: 0px !important;
}

.SkillGroup_Label.side_label {
	position: absolute;
	transform: translate(-50%, -50%) rotateZ(-90deg);
	top: 25%;
	left: -0.25rem;
}

.SkillGroup_Label.side_label h2 {
	font-size: 1.5rem;
}

.SkillIcon {
	--transition: all 0.35s var(--timing-func-aggressive-overshoot);
	transition: var(--transition);
}

.SkillIcon_Badge {
	transition: var(--transition);
}

.SkillIcon:hover .SkillIcon_Badge {
	outline-width: 0.3rem !important;
	outline-offset: 0.25rem;

	animation: BadgeWobble 0.5s 1 cubic-bezier(0.49, -0.45, 0.51, 1.4);
}

@keyframes BadgeWobble {
	0%,
	100% {
		transform: rotateZ(0deg);
	}

	33% {
		transform: rotateZ(10deg);
	}

	66% {
		transform: rotateZ(-7.5deg);
	}
}

.SkillIcon.Master:after {
	transition: var(--transition);

	font-family: 'Material Symbols Outlined';
	font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;

	font-size: 1.75rem;
	content: '\f454';
	color: rgba(var(--nmd-tk-warning-rgb), 0.75);

	text-shadow: -1px 3px 4px var(--nmd-tk-body-bg);

	position: absolute;
	z-index: 10;
	top: -1rem;
	left: 50%;
	transform: translateX(-50%);
}

.SkillIcon.Master:hover:after {
	top: -1.2rem;
	text-shadow: -1px calc(3px + 0.2rem) calc(4px + 0.2rem) var(--nmd-tk-body-bg);
}

.SkillIcon_Label {
	transition: var(--transition);
	transition-timing-function: var(--timing-func-spring-out-soft);

	pointer-events: none;
	position: absolute;
	z-index: 100;

	white-space: nowrap;

	top: 50%;
	left: 50%;

	transform: translate(-50%, -150%) scale(0.1);

	opacity: 0;
}

.SkillIcon:hover .SkillIcon_Label {
	opacity: 1;
	top: 0%;
	transform: translate(-50%, -150%) scale(1);
}

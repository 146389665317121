.Grid {
	--transition-timing-function: var(--timing-func-windup-in-out);

	transition: all 0.5s var(--transition-timing-function);
}

.GridCell {
	transition-property: top, left, opacity, width, height;
	transition-duration: 0.5s;
	transition-timing-function: var(--transition-timing-function);

	--transition-delay: 0s;
	transition-delay: var(--transition-delay);
}

.GridCellContent {
	--outline-width: 2px;
	--outline-color: var(--nmd-tk-border-color);
	--outline-offset: -1px;
	transition: border-radius 0.25s var(--transition-delay) linear, outline 0.1s ease-out, outline-offset 0.1s ease-out;
}

@media not ((hover: none) or (pointer: coarse)) {
	/* Not touch devices */
	.GridCellContent:hover {
		--outline-width: 4px;
		--outline-offset: -5px;
		--outline-color: var(--nmd-tk-light);
	}
}
